import { PortableText } from "@portabletext/react";
import { Link } from "react-router-dom";
import { urlFor } from "../utils/imageBuilder";
import { Container } from "./Container";

export function Highlights(props) {
  return (
    <Container size="lg" className="my-16">
      <ol
        role="list"
        className="-mx-3 grid grid-cols-1 gap-y-10 lg:grid-cols-3 lg:text-center xl:-mx-12 xl:divide-x xl:divide-slate-400/20"
      >
        {props?.highlights?.map((resource) => (
          <li
            key={resource.title}
            className="grid auto-rows-min grid-cols-1 items-center gap-8 px-3 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-1 xl:px-12"
          >
            {resource.image && (
              <div className="relative h-48 overflow-hidden rounded-2xl shadow-lg sm:h-60 lg:h-40">
                <div className="absolute inset-0 flex items-center justify-center">
                  <img
                    src={urlFor(resource.image).width(640).url()}
                    alt=""
                    unoptimized
                    className="h-full w-full object-cover"
                  />
                </div>
              </div>
            )}
            {resource.imageUrl && (
              <div className="relative h-48 overflow-hidden rounded-2xl shadow-lg sm:h-60 lg:h-40">
                <div className="absolute inset-0 flex items-center justify-center">
                  <img
                    src={resource.imageUrl}
                    alt=""
                    unoptimized
                    className="h-full w-full object-cover"
                  />
                </div>
              </div>
            )}
            <div>
              <h3 className="text-base font-medium tracking-tight text-slate-900">
                {resource.title}
              </h3>
              <p className="mt-2 text-sm text-slate-600">
                <div className="prose prose-headings:font-condensed prose-headings:tracking-tight prose-h2:text-4xl prose-h3:text-3xl prose-h4:text-2xl prose-sm sm:prose-base">
                  <PortableText value={resource.description} />
                  {resource.simpleDescription && (
                    <p>{resource.simpleDescription}</p>
                  )}
                  {resource.link && <Link to={resource.link}>Learn More</Link>}
                  {resource.href && (
                    <a href={resource.href} target="_blank">
                      Learn More
                    </a>
                  )}
                </div>
              </p>
            </div>
          </li>
        ))}
      </ol>
    </Container>
  );
}
