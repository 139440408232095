import { Container } from "../components/Container";
import { urlFor } from "../utils/imageBuilder";

export function VideoTestimonial({
  id,
  author,
  vimeoId,
  image,
  staticImage,
  children,
}) {
  return (
    <aside
      id={id || ""}
      className="relative bg-slate-900 py-16 sm:py-32 overflow-hidden"
    >
      {image && (
        <img
          src={urlFor(image).width(1920).url()}
          className="inset-0 absolute w-full h-full object-cover opacity-50 blur-sm scale-105"
        />
      )}
      {staticImage && (
        <img
          src={staticImage}
          className="inset-0 absolute w-full h-full object-cover opacity-50 blur-sm scale-105"
        />
      )}
      <Container size="lg" className="relative">
        <div className="flex flex-col-reverse lg:flex-row-reverse items-center gap-16">
          <figure className="w-full">
            <blockquote className="font-display text-4xl font-medium tracking-tight text-slate-100 text-center lg:text-left">
              &ldquo;{children}&rdquo;
            </blockquote>
            {author && (
              <figcaption className="mt-10 flex items-center justify-center lg:justify-start">
                {author.image && (
                  <div className="overflow-hidden rounded-full bg-slate-800">
                    <img
                      className="h-12 w-12 object-cover"
                      src={author.image}
                      alt=""
                      width={48}
                      height={48}
                    />
                  </div>
                )}

                <div className="text-center lg:text-left">
                  {author.name && (
                    <div className="text-base font-medium leading-6 tracking-tight text-slate-100">
                      {author.name}
                    </div>
                  )}
                  {author.role && (
                    <div className="mt-1 text-sm text-slate-300 capitalize">
                      {author.role}
                    </div>
                  )}
                </div>
              </figcaption>
            )}
          </figure>
          {vimeoId && (
            <div className="aspect-video">
              <iframe
                src={`https://player.vimeo.com/video/${vimeoId}?title=0&byline=0&portrait=0?`}
                width="640"
                height="360"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                className="rounded-lg shadow-2xl shadow-black border-t border-slate-300/50 w-[calc(100vw-4em)] h-[calc((100vw-4em)/16*9)] md:w-[640px] md:h-[360px]"
              ></iframe>
            </div>
          )}
        </div>
      </Container>
    </aside>
  );
}
