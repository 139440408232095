import { getDistance, convertDistance } from "geolib";
import { useEffect, useState } from "react";

export const Distance = (props) => {
  const [distance, setDistance] = useState(0);

  const updateDistance = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log(position);

        const distance = getDistance(
          {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
          {
            latitude: props.lat,
            longitude: props.long,
          }
        );

        setDistance(Math.round(convertDistance(distance, "mi") * 10) / 10);
      },
      () => {
        setDistance(null);
      }
    );
  };

  return (
    <>
      {!!distance ? (
        <p className="bg-slate-100 rounded-md text-base inline-flex items-center gap-2 mt-2 flex-row px-3 py-1.5 text-slate-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-4 h-4"
          >
            <path
              fill-rule="evenodd"
              d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 00.281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 103 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 002.273 1.765 11.842 11.842 0 00.976.544l.062.029.018.008.006.003zM10 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z"
              clip-rule="evenodd"
            />
          </svg>
          <p>
            <span className="font-bold">{distance}</span> mi
          </p>
        </p>
      ) : (
        <div
          className="bg-slate-100 rounded-md text-base inline-flex items-center gap-2 mt-2 flex-row px-3 py-1.5 text-slate-600 cursor-pointer transition hover:bg-slate-200"
          onClick={updateDistance}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-4 h-4"
          >
            <path
              fill-rule="evenodd"
              d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 00.281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 103 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 002.273 1.765 11.842 11.842 0 00.976.544l.062.029.018.008.006.003zM10 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z"
              clip-rule="evenodd"
            />
          </svg>
          <p>
            <span className="font-bold text-sm">Get Distance</span>
          </p>
        </div>
      )}
    </>
  );
};
