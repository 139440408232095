import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import School from "./pages/school.js";
import Page from "./pages/page.js";
import Home from "./pages/home.js";
import Schools from "./pages/schools.js";
import Page404 from "./pages/404.js";
import FocusPrograms from "./pages/focusPrograms.js";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/schools" element={<Schools />} /> */}
        {/* <Route path="/focus-programs" element={<FocusPrograms />} /> */}
      
        <Route path="/schools/:slug" element={<School />} />
        <Route path="*" element={<Home />} />
        {/* <Route path="/:slug" element={<Page />} /> */}
        {/* <Route path="*" element={<Navigate to="/schools/options" replace />} /> */}
      </Routes>
    </BrowserRouter>
  );
}
export default App;
