import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);

export default function Schools() {
  const [allPostsData, setAllPosts] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "school"]{
          title,
          slug,
          logo, 
          color,
          mainImage,
        }
      `
      )
      .then((data) => {
        setAllPosts(data);
        console.log(data);
      })
      .catch(console.error);
  }, []);

  return (
    <>
      <div className="grid md:grid-cols-2 lg:grid-cols-4 w-screen h-screen grid-cols-4 lg:grid-rows-2
      ">
        {allPostsData &&
          allPostsData.map((post, index) => (
            <article>
              <Link
                to={"/schools/" + post.slug.current}
                key={post.slug.current}
              >
                <div className="w-full h-full flex flex-col items-center justify-center overflow-hidden relative group"
                >
                     {post.mainImage && (
                    <img
                      src={builder
                        .image(post.mainImage)
                        .width(600)
                        .height(600)
                        .url()}
                      alt={post.title}
                      className="absolute w-full h-full object-cover lg:object-bottom inset-0 transition-all duration-1000 scale-[101%] group-hover:scale-105 "
                    />
                  )}
                  <div className="w-full h-full bg-black absolute inset-0 opacity-0 group-hover:opacity-50 transition-all duration-1000 "  style={post.color ? { borderColor: post.color.hex } : { borderColor: "#ffffff" }}></div>
                  <div className="z-10 w-full h-full relative flex items-center justify-end flex-col">
               
                  {post.logo && (
                    <img
                      src={builder
                        .image(post.logo)
                        .width(100)
                        .height(100)
                        .url()}
                      alt={post.title}
                      className="w-8 h-8 object-contain"
                    />
                  )}
                  <span className="font-bold text-3xl font-display text-center uppercase text-white">{post.title}</span>
                  </div>
                </div>
              </Link>
            </article>
          ))}
      </div>
    </>
  );
}
