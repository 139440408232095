import { AcademicCapIcon } from "@heroicons/react/20/solid";
import { CheckIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import FocusProgramSection from "../components/FocusProgramSection.js";

const links = [
  {
    name: "EHS",
    href: "/schools/ehs",
  },
  {
    name: "CETC",
    href: "/schools/cetc",
  },
  {
    name: "IC",
    href: "/schools/innovation",
  },
  {
    name: "NHS",
    href: "/schools/nhs",
  },
  {
    name: "SHS",
    href: "/schools/shs",
  },
  {
    name: "FHS",
    href: "/schools/fhs",
  },
  {
    name: "LHS",
    href: "/schools/lhs",
  },
  {
    name: "MHS",
    href: "/schools/mhs",
  },
  {
    name: "LMSHS",
    href: "/schools/lmshs",
  },
  {
    name: "SCHS",
    href: "/schools/schs",
  },
];

const agricultureList = [
  {
    name: "Agriscience",
    schools: ["CETC"],
    icon: "fa-seedling",
    links: ["https://cetc.svvsd.org/pathways/agricultural-sciences/"]
  },
];

const artList = [
  {
    name: "Interior/Fashion Design",
    schools: ["NHS"],
    icon: "fa-shirt",
  },
  {
    name: "Journalism and Broadcasting",
    schools: ["EHS", "IC"],
    icon: "fa-microphone",
    links: [null, "https://innovation.svvsd.org/focus-areas/ic-studios/"]
  },
  {
    name: "Multimedia and Video/Audio Production",
    schools: ["CETC", "IC"],
    icon: "fa-video",
    links: ["https://cetc.svvsd.org/pathways/interactive-media-technology/", "https://innovation.svvsd.org/focus-areas/ic-studios/"]
  },
  {
    name: "Virtual and Digital Design",
    schools: ["IC"],
    icon: "fa-laptop",
    links: ["https://innovation.svvsd.org/focus-areas/virtual-and-digital-design/"]
  },
  {
    name: "Visual and Performing Arts",
    schools: ["All Schools"],
    icon: "fa-palette",
  },
  {
    name: "Visual and Performing Arts Academy",
    schools: ["SHS"],
    icon: "fa-masks-theater",
    links: ["https://shs.svvsd.org/academics/stem-vpa/"]
  },
];
const businessList = [
  {
    name: "Business",
    schools: ["FHS", "MHS", "SCHS"],
    icon: "fa-briefcase",
  },
  {
    name: "Entrepreneurship",
    schools: ["IC"],
    icon: "fa-handshake",
    links: ["https://innovation.svvsd.org/focus-areas/entrepreneurship/"]
  },
  {
    name: "High School of Business",
    schools: ["LHS"],
    icon: "fa-briefcase",
  },
  {
    name: "P-TECH in Business",
    schools: ["LHS"],
    icon: "fa-briefcase",
    links: ["http://lhs.svvsd.org/wp-content/uploads/sites/26/2022/11/2023-TrojanTECH-Flyer.pdf"]
  },
];

const educationList = [
  {
    name: "International Baccalaureate",
    schools: ["NHS"],
    icon: "fa-globe",
    links: ["https://nhs.svvsd.org/academics/international-baccalaureate/"]
  },
  {
    name: "Pathways to Teaching (P-Teach)",
    schools: ["IC"],
    icon: "fa-chalkboard-teacher",
    links: ["https://innovation.svvsd.org/focus-areas/pathways-to-teaching/"]
  },
];

const governmentList = [
  {
    name: "Leadership Academy",
    schools: ["SCHS"],
    icon: "fa-user-tie-hair-long",
  },
  {
    name: "District Student Senate",
    schools: ["All Schools"],
    icon: "fa-podium",
    links: ["https://schs.svvsd.org/academics/silver-creek-leadership-academy-scla/"]
  },
];

const healthList = [
  {
    name: "Athletic Training",
    schools: ["CETC"],
    icon: "fa-person-running",
    links: ["https://cetc.svvsd.org/pathways/health-sciences/"]
  },
  {
    name: "Biomedical Sciences Academy",
    schools: ["FHS"],
    icon: "fa-microscope",
    links: ["https://fhs.svvsd.org/academics/biomedical-science-academy/"]
  },
  {
    name: "Bioscience and Neuroscience",
    schools: ["IC"],
    icon: "fa-brain",
    links: ["https://innovation.svvsd.org/focus-areas/bioscience/"]
  },
  {
    name: "EMT Certification",
    schools: ["CETC"],
    icon: "fa-truck-medical",
    links: ["https://cetc.svvsd.org/pathways/health-sciences/"]
  },
  {
    name: "Medical and BioScience Academy",
    schools: ["LHS"],
    icon: "fa-dna",
    links: ["https://lhs.svvsd.org/academics/medical-bioscience-academy/"]
  },
  {
    name: "Medical/Clinical Assistant",
    schools: ["CETC"],
    icon: "fa-stethoscope",
    links: ["https://cetc.svvsd.org/pathways/health-sciences/"]
  },
  {
    name: "P-TECH in Biochemistry",
    schools: ["FHS"],
    icon: "fa-atom",
    links: ["https://fhs.svvsd.org/academics/p-tech/"]
  },
  {
    name: "Nurse Aide",
    schools: ["CETC"],
    icon: "fa-user-nurse",
    links: ["https://cetc.svvsd.org/pathways/health-sciences/"]
  },
];

const hospitalityList = [
  {
    name: "Hospitality",
    schools: ["MHS"],
    icon: "fa-hotel",
  },
  {
    name: "ProStart/Culinary Arts",
    schools: ["CETC"],
    icon: "fa-utensils",
    links: ["https://cetc.svvsd.org/pathways/prostart-culinary-arts/"]
  },
];

const technologyList = [
  {
    name: "Artificial Intelligence",
    schools: ["IC"],
    icon: "fa-robot",
    links: ["https://innovation.svvsd.org/programs/competitive-project-student-teams/"]
  },
  {
    name: "Computer Science",
    schools: ["All Schools"],
    icon: "fa-laptop-code",
  },
  {
    name: "Cybersecurity",
    schools: ["IC"],
    icon: "fa-shield-alt",
    links: ["https://innovation.svvsd.org/focus-areas/cybersecurity/"]
  },
  {
    name: "Information and Communication Technologies",
    schools: ["IC"],
    icon: "fa-laptop",
    links: ["https://innovation.svvsd.org/focus-areas/information-communications-technology/"]
  },
  {
    name: "P-TECH in Computer Information Systems",
    schools: ["SHS"],
    icon: "fa-laptop-code",
    links: ["https://shs.svvsd.org/academics/p-tech/"]
  },
  {
    name: "P-TECH in Cybersecurity",
    schools: ["SCHS"],
    icon: "fa-shield-alt",
    links: ["https://schs.svvsd.org/academics/raptor-tech/"]
  },
  {
    name: "Robotics",
    schools: ["IC"],
    icon: "fa-robot",
    links: ["https://innovation.svvsd.org/focus-areas/robotics/"]
  },
  {
    name: "Virtual and Augmented Reality",
    schools: ["IC"],
    icon: "fa-vr-cardboard",
    links: ["https://innovation.svvsd.org/focus-areas/virtual-and-digital-design/"]
  },
];

// EMT/Paramedic: CETC
// Police Academy: FHS
// Pre-Law: CETC

const lawList = [
  {
    name: "EMT/Paramedic",
    schools: ["CETC"],
    icon: "fa-ambulance",
    links: ["https://cetc.svvsd.org/pathways/health-sciences/"]
  },
  {
    name: "Police Academy",
    schools: ["FHS"],
    icon: "fa-shield-alt",
  },
  {
    name: "Pre-Law",
    schools: ["CETC"],
    icon: "fa-gavel",
    links: ["https://cetc.svvsd.org/pathways/pre-law/"]
  },
];

// Advanced Manufacturing Academy: CETC
// Welding Fabrication: CETC

const manufacturingList = [
  {
    name: "Advanced Manufacturing Academy",
    schools: ["CETC"],
    icon: "fa-industry",
    links: ["https://cetc.svvsd.org/pathways/advanced-manufacturing/"]
  },
  {
    name: "Welding Fabrication",
    schools: ["CETC"],
    icon: "fa-wrench",
    links: ["https://cetc.svvsd.org/pathways/welding-fabrication-technology/"]
  },
];

const stemList = [
  {
    name: "Academy of Engineering and Aerospace",
    schools: ["EHS"],
    icon: "fa-rocket",
    links: ["https://ehs.svvsd.org/academics/academy-of-engineering-aerospace/"]
  },
  {
    name: "Aeronautics",
    schools: ["IC"],
    icon: "fa-plane",
    links: ["https://innovation.svvsd.org/focus-areas/aeronautics/"]
  },
  {
    name: "Bioscience and Data Science",
    schools: ["IC"],
    icon: "fa-dna",
    links: ["https://innovation.svvsd.org/focus-areas/bioscience/"]
  },
  {
    name: "Energy Academy",
    schools: ["MHS"],
    icon: "fa-bolt",
    links: ["https://mhs.svvsd.org/academics/energy-academy/"]
  },
  {
    name: "Mobile Innovation Lab",
    schools: ["IC"],
    icon: "fa-bus",
    links: ["http://mobilelab.svvsd.org/"]
  },
  {
    name: "Robotics",
    schools: ["IC"],
    icon: "fa-robot",
    links: ["https://innovation.svvsd.org/focus-areas/robotics/"]
  },
  {
    name: "Science and Environmental Leadership",
    schools: ["LMSHS"],
    icon: "fa-flask",
  },
  {
    name: "STEM Academy",
    schools: ["SHS"],
    icon: "fa-calculator",
    links: ["https://shs.svvsd.org/academics/stem-vpa/"]
  },
];

// Automotive Technology: CETC

const transportationList = [
  {
    name: "Automotive Technology",
    schools: ["CETC"],
    icon: "fa-car",
  },
];

export default function FocusPrograms() {
  return (
    <>
      <div className="pb-16 xl:flex xl:items-center xl:justify-between mt-8">
        <div className="prose prose-headings:font-condensed prose-headings:tracking-tight prose-h2:text-4xl prose-h3:text-3xl prose-h4:text-2xl prose-sm sm:prose-base mt-8 max-w-3xl">
        <div>
                  <div className="flex h-12 w-12 items-center justify-center rounded-md bg-[color:var(--bg-color)] text-white">
                    <i class="fa-duotone fa-split h-5 w-5"></i>
                  </div>
                </div>
          <h1 className="text-4xl font-bold tracking-tight sm:text-5xl mt-6">
            <span className="text-gray-900">Industry-Leading </span>
            <span className="text-[#862633]">Focus Pathways</span>
          </h1>
          <p className="text-xl text-gray-500">
            St. Vrain Valley Schools is giving students a strong competitive
            advantage for success in postsecondary studies and careers through
            many outstanding focus programs, industry credentials, and
            real-world learning experiences.
          </p>
        </div>
        {/* <a
            href="#"
            className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-5 py-3 text-base font-medium text-white hover:bg-indigo-700 sm:mt-10 sm:w-auto xl:mt-0"
          >
            Get started today
          </a> */}
      </div>
      <div className="xl:grid xl:grid-cols-3 xl:gap-x-8 border-t border-gray-300 py-16">
        <div className=" flex flex-col gap-y-12 pb-12">
          <FocusProgramSection
            list={agricultureList}
            title="Agriculture, Food, and Natural Resources"
            color="text-green-500"
          />
          <FocusProgramSection
            list={artList}
            title="Arts, A/V Technology, and Communications"
            color="text-sky-500"
          />
          <FocusProgramSection
            list={businessList}
            title="Business, Management, and Administration"
            color="text-navy-700"
          />
          <FocusProgramSection
            list={educationList}
            title="Education and Training"
            color="text-red-800"
          />
          <FocusProgramSection
            list={governmentList}
            title="Government and Public Administration"
            color="text-orange-500"
          />
        </div>
        <div className="flex flex-col gap-y-12 pb-12">
          <FocusProgramSection
            list={healthList}
            title="Health Science"
            color="text-yellow-400"
          />
          <FocusProgramSection
            list={hospitalityList}
            title="Hospitality and Tourism"
            color="text-navy-700"
          />
          <FocusProgramSection
            list={technologyList}
            title="Information Technology"
            color="text-red-800"
          />
        </div>

        <div className="flex flex-col gap-y-12 pb-12">
          <FocusProgramSection
            list={lawList}
            title="Law, Public Safety, Corrections and Security"
            color="text-orange-500"
          />
          <FocusProgramSection
            list={manufacturingList}
            title="Manufacturing"
            color="text-yellow-400"
          />
          <FocusProgramSection
            list={stemList}
            title="Science, Technology, Engineering, and Mathematics"
            color="text-green-500"
          />
          <FocusProgramSection
            list={transportationList}
            title="Transportation"
            color="text-sky-500"
          />
        </div>
      </div>
    </>
  );
}
