import { Link } from "react-router-dom";

export default function FocusProgramSection(props) {
  return (
    <div className="">
      <p className="text-3xl font-bold tracking-tight text-gray-900">
        {props.title}
      </p>
      <p className="mt-4 text-lg text-gray-500">{props.description}</p>
      <ul role="list" className="divide-y divide-gray-200">
        {props.list.map((item) => (
          <li className="flex py-4 items-center">
            <i className={`fa-duotone ${item.icon} ${props.color}`}></i>
            <span className="ml-3 text-base text-gray-500">
              {item.name}:{" "}
              {item.schools.map((school, index) => (
                <>
                  {school === "All Schools" ? (
                    school
                  ) : (
                    <>
                      {item.links ? (
                        <>
                          {item.links[index] ? (
                            <a  className="font-bold underline hover:text-gray-700" href={item.links[index]} target="_blank">{school}</a>
                          ) : (
                            <Link
                              to={`/schools/${school.toLowerCase()}`}
                              className="font-bold underline hover:text-gray-700"
                            >
                              {school}
                            </Link>
                          )}
                        </>
                      ) : (
                        <Link
                          to={`/schools/${school.toLowerCase()}`}
                          className="font-bold underline hover:text-gray-700"
                        >
                          {school}
                        </Link>
                      )}
                    </>
                  )}
                  {item.schools.indexOf(school) !== item.schools.length - 1
                    ? ", "
                    : ""}
                </>
              ))}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}
