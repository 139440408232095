import clsx from "clsx";
import { urlFor } from "../utils/imageBuilder";

export function Photos(props) {
  let rotations = [
    "rotate-2",
    "-rotate-2",
    "rotate-2",
    "rotate-2",
    "-rotate-2",
  ];

  if (!props.photos || props.photos.length === 0) {
    return null;
  }

  return (
    <div className="my-8">
      <div className="-my-4 flex justify-center gap-5 overflow-hidden py-4 sm:gap-8">
        {props.photos.map((image, imageIndex) => (
          <>
            {image.asset && (
              <div
                key={image._key}
                className={clsx(
                  "relative aspect-[9/10] w-44 flex-none overflow-hidden  shadow rounded-xl sm:w-72 sm:rounded-2xl",
                  rotations[imageIndex % rotations.length]
                )}
              >
                <img
                  src={urlFor(image.asset).width(640).url()}
                  alt=""
                  sizes="(min-width: 640px) 18rem, 11rem"
                  className="absolute inset-0 h-full w-full object-cover"
                />
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );
}
