import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  ChatBubbleBottomCenterTextIcon,
  ChatBubbleLeftRightIcon,
  InboxIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useRef, useEffect, useState } from "react";
import SvvsdLogo from "../images/svvsd-logo.png";

import {
  ChartBarIcon,
  CheckCircleIcon,
  CursorArrowRaysIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const callsToAction = [
  {
    name: "Career Elevation and Technology Center",
    href: "/schools/cetc",
    icon: "https://cdn.sanity.io/images/mx4mn3pt/production/f5a3728dcb2fc44b2e3564d965a454fe333e1909-500x462.png?w=64&h=64&&auto=format",
  },
  {
    name: "Innovation Center",
    href: "/schools/innovation",
    icon: "https://cdn.sanity.io/images/mx4mn3pt/production/5e3890409180b236f7494eb1cde1ae1ba5450cf4-1200x1333.png?w=64&h=64&&auto=format",
  },
];

const schools = [
  {
    name: "Erie High School",
    description:
      "Erie High provides a rigorous and comprehensive high school experience, alongside an outstanding and supportive culture.",
    href: "/schools/ehs",
    icon: "https://cdn.sanity.io/images/mx4mn3pt/production/1542d8079b248ccb1fdfb008f75cc08d1a056366-557x508.svg?w=64&h=64&&auto=format",
  },
  {
    name: "Frederick High School",
    description:
      "Building on a foundation of trust, honor, and compassion, we learn, lead, and succeed.",
    href: "/schools/fhs",
    icon: "https://cdn.sanity.io/images/mx4mn3pt/production/cbcbe02bdf268db1ba585d8808ed7ce7f59dd88f-1000x1000.jpg?w=64&h=64&&auto=format",
  },
  {
    name: "LaunchED Virtual Academy",
    description:
      "Erie High provides a rigorous and comprehensive high school experience, alongside an outstanding and supportive culture.",
    href: "/schools/launched",
    icon: "https://cdn.sanity.io/images/mx4mn3pt/production/9e3a669c973d2a5b779eaf9a5484c48930ffc78d-500x341.png?w=64&h=64&&auto=format",
  },

  {
    name: "Longmont High School",
    description:
      "The Trojan Way - Tradition, Respect, Ownership, Journey, Achieve, Never Quit.",
    href: "/schools/lhs",
    icon: "https://cdn.sanity.io/images/mx4mn3pt/production/bebf1bbe073d7450f153a986eb14fc044a9530b5-261x291.svg?w=64&h=64&&auto=format",
  },

  {
    name: "Lyons Middle Senior High School",
    description:
      "Erie High provides a rigorous and comprehensive high school experience, alongside an outstanding and supportive culture.",
    href: "/schools/lmshs",
    icon: "https://cdn.sanity.io/images/mx4mn3pt/production/d7d1c1a68604207b1420756d848d5ce7d802e283-218x232.svg?w=64&h=64&&auto=format",
  },
  {
    name: "Mead High School",
    description: "We prepare students for the ever-changing world.",
    href: "/schools/mhs",
    icon: "https://cdn.sanity.io/images/mx4mn3pt/production/1c2b1bec65bd25b19afdbb2a06aeebc37ec3f53a-230x229.svg?w=64&h=64&&auto=format",
  },
  {
    name: "Niwot High School",
    description:
      "Together, we are champions of opportunity, united through globalized academics and rigor.",
    href: "/schools/nhs",
    icon: "https://cdn.sanity.io/images/mx4mn3pt/production/d2aa0e488c01d0114250fdae7e14c24a8def0a69-1500x1500.jpg?w=64&h=64&&auto=format",
  },
  {
    name: "New Meridian High School",
    description:
      "Erie High provides a rigorous and comprehensive high school experience, alongside an outstanding and supportive culture.",
    href: "/schools/nmhs",
    icon: "https://cdn.sanity.io/images/mx4mn3pt/production/611bacb7e93ac7937748f4bdd08eee60db4e2d9b-500x437.png?w=64&h=64&&auto=format",
  },

  {
    name: "Silver Creek High School",
    description:
      "We are an inclusive community, committed to leadership and excellence in academics, the arts, and athletics.",
    href: "/schools/schs",
    icon: "https://cdn.sanity.io/images/mx4mn3pt/production/d62b708a20caf0c5569231abfe6134c5a89dac6f-177x199.svg?w=64&h=64&&auto=format",
  },
  {
    name: "Skyline High School",
    description:
      "Our mission is to challenge students to achieve the highest levels of academic success so they may thrive in an increasingly diverse and complex world.",
    href: "/schools/shs",
    icon: "https://cdn.sanity.io/images/mx4mn3pt/production/41c6ee7e55d161f10e2d673fe348817a15695038-260x357.svg?w=64&h=64&&auto=format",
  },

  {
    name: "St. Vrain Virtual High School",
    description:
      "Erie High provides a rigorous and comprehensive high school experience, alongside an outstanding and supportive culture.",
    href: "/schools/svvhs",
    icon: "https://cdn.sanity.io/images/mx4mn3pt/production/5959f6ed44a18fc7998c81f05b708302a1bbd3e5-223x184.jpg?w=64&h=64&&auto=format",
  },
];
const navigation = [
  { name: "The St. Vrain Advantage", href: "/#advantage" },
  { name: "Districtwide Programs", href: "/#district-programs" },
  { name: "Focus Pathways", href: "/#focus-programs" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function HomeHero() {
  return (
    <div className="bg-white">
      <header>
        <Popover className="relative bg-white">
          <div className="mx-auto flex max-w-7xl items-center justify-between px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href="/">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-8 w-auto sm:h-10 flex-shrink-0 object-contain"
                  src={SvvsdLogo}
                  alt=""
                />
              </a>
            </div>
            <div className="-my-2 -mr-2 md:hidden">
              <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#862633]">
                <span className="sr-only">Open menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden space-x-10 md:flex">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-base font-medium text-center text-gray-500 hover:text-gray-900"
                >
                  {item.name}
                </a>
              ))}
              <Popover className="">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-gray-900" : "text-gray-500",
                        "group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-[#862633] focus:ring-offset-2"
                      )}
                    >
                      <span>High School Options</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-gray-600" : "text-gray-400",
                          "ml-2 h-5 w-5 group-hover:text-gray-500"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 -translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 -translate-y-1"
                    >
                      <Popover.Panel className="absolute inset-x-0 z-50 transform shadow-lg mt-2">
                        <div className="bg-white">
                          <div className="mx-auto max-w-7xl grid gap-y-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-12 xl:py-16">
                            {schools.map((item) => (
                              <Popover.Button
                                as={Link}
                                key={item.name}
                                to={item.href}
                                className="-m-3 flex flex-col justify-between rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-50"
                              >
                                <div className="flex md:h-full lg:flex-row items-center gap-2 justify-center">
                                  <div className="flex-shrink-0">
                                    <div className="inline-flex h-10 w-10 items-center justify-center rounded-md bg-white text-white sm:h-12 sm:w-12 overflow-hidden shadow p-2">
                                      {item.icon && (
                                        <img
                                          src={item.icon}
                                          className="w-full h-full object-contain"
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <div className="ml-4 md:flex md:flex-1 md:flex-col md:justify-between lg:ml-0 ">
                                    <div>
                                      <p className="text-base font-medium text-gray-900">
                                        {item.name}
                                      </p>
                                      {/* <p className="mt-1 text-sm text-gray-500">
                                        {item.description}
                                      </p> */}
                                    </div>
                                  </div>
                                </div>
                              </Popover.Button>
                            ))}
                          </div>
                        </div>
                        <div className="bg-white">
                          <div className="mx-auto max-w-7xl space-y-6 px-4 sm:flex sm:space-y-0 sm:space-x-10 sm:px-6 lg:px-8 bg-white">
                            <span className="text-gray-600 text-xs uppercase bg-gray-50 rounded-t-md py-2 px-3 -mx-3">
                              Innovation & Career Pathways
                            </span>
                          </div>
                        </div>
                        <div className="bg-gray-50">
                          <div className="mx-auto max-w-7xl space-y-6 px-4 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-6 lg:px-8">
                            {callsToAction.map((item) => (
                              <div key={item.name} className="flow-root">
                                <Popover.Button
                                  as={Link}
                                  to={item.href}
                                  className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 transition duration-150 ease-in-out hover:bg-gray-100"
                                >
                                  {/* <item.icon
                                    className="h-6 w-6 flex-shrink-0 text-gray-400"
                                    aria-hidden="true"
                                  /> */}
                                  <div className="inline-flex h-10 w-10 items-center justify-center rounded-md bg-white text-white sm:h-12 sm:w-12 overflow-hidden shadow p-2">
                                    {item.icon && (
                                      <img
                                        src={item.icon}
                                        className="w-full h-full object-contain"
                                      />
                                    )}
                                  </div>
                                  <span className="ml-3">{item.name}</span>
                                </Popover.Button>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </Popover.Group>
          </div>

          <Transition
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="fixed h-screen overflow-hidden inset-x-0 top-0 z-[999] origin-top-right transform transition md:hidden"
            >
              <div className="divide-y-2 divide-gray-50  bg-white shadow-lg ring-1 ring-black ring-opacity-5 pb-8">
                <div className="px-5 pt-5 pb-6">
                  <div className="flex items-center justify-between">
                    <div>
                      <img className="h-8 w-auto" src={SvvsdLogo} />
                    </div>
                    <div className="-mr-2 sticky top-0">
                      <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#862633]">
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="mt-6">
                    <nav className="grid grid-cols-1 relative gap-7 max-h-[calc(100vh-240px)] overflow-y-auto overflow-x-hidden pb-12">
                      <div className="h-16 bottom-36 inset-x-0 mr-16 bg-gradient-to-t from-white via-white fixed pointer-events-none"></div>
                      <span className="text-gray-600 text-xs uppercase mt-2">
                        High School Options
                      </span>
                      {schools.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="-m-3 flex items-center rounded-lg p-3 hover:bg-gray-50"
                        >
                          <div className="inline-flex h-10 w-10 items-center justify-center rounded-md bg-white text-white sm:h-12 sm:w-12 overflow-hidden shadow p-2">
                            {item.icon && (
                              <img
                                src={item.icon}
                                className="w-full h-full object-contain"
                              />
                            )}
                          </div>

                          <div className="ml-4 text-base font-medium text-gray-900">
                            {item.name}
                          </div>
                        </a>
                      ))}
                      <span className="text-gray-600 text-xs uppercase mt-2">
                        Innovation & Career Pathways
                      </span>
                      {callsToAction.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="-m-3 flex items-center rounded-lg p-3 hover:bg-gray-50"
                        >
                          <div className="inline-flex h-10 w-10 items-center justify-center rounded-md bg-white text-white sm:h-12 sm:w-12 overflow-hidden shadow p-2">
                            {item.icon && (
                              <img
                                src={item.icon}
                                className="w-full h-full object-contain"
                              />
                            )}
                          </div>

                          <div className="ml-4 text-base font-medium text-gray-900">
                            {item.name}
                          </div>
                        </a>
                      ))}
                    </nav>
                  </div>
                </div>
                <div className="py-6 px-5">
                  <div className="grid grid-cols-2 gap-4">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="text-base font-medium text-gray-900 hover:text-gray-700"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </header>
    </div>
  );
}
