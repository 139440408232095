import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { OpenHouse } from "../components/OpenHouse";
import { Footer } from "../components/Footer";
import { Hero } from "../components/Hero";
import { NavBar } from "../components/NavBar";
import sanityClient, { previewClient } from "../client.js";
import { ContentSection } from "../components/ContentSection";
import debounce from "lodash.debounce";
import SiteNavigation from "../components/SiteNavigation";
import ReturnToTop from "../components/ReturnToTop";

export default function School() {
  let location = useLocation();

  const [postData, setPostData] = useState(null);
  const { slug } = useParams();
  const [preview, setPreview] = useState(false);
  const [postId, setPostId] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(null);

  let queryParams;
  if (typeof window !== "undefined") {
    queryParams = new URLSearchParams(window.location.search);
  }

  const idQuery = /* js */ `*[slug.current == "${slug}"]{
    _id,
  }`;

  const draftQuery = /* js */ `
  *[_id == "drafts.${postId}"]{
    _id,
    title,
    subtitle,
    slug,
    logo,
    color,
    location,
    "featuredQuote": featuredQuote->{
      _id,
      quote,
      student,
      year,
      school->{
        title,
      }
    },
    twitter,
    seoDescription,
    seoImage,
    tourTitle,
    tourDescription,
    tourLink,
    tourImage,
    mainImage,
    sectionRepeater[]{
      ...,
      title,
      href,
      section[]{
        ...,
        accordion,
        "accordionSection": accordionSection->{
          sectionTitle,
          columns,
          topPadding,
          accordion[]{
            ...,
            defaultOpen,
            accordionTitle,
            accordionContent,
          }
        },
        content,
        photo,
        photos,
        "highlightSection": highlightSection->{
          _id,
          title,
          highlights[]{
            ...,
            title,
            description,
            image,
          }
        },
        tagList,
        "testimonial": testimonial->{
          _id,
          quote,
          student,
          year,

          school->{
            title,
          }
        },
        "video": video->{
          _id,
          title,
          videoId,
        },
        "videoTestimonial": videoTestimonial->{
          _id,
          "testimonial": testimonial->{
            _id,
            quote,
            student,
            year,
            school->{
              title,
            }
          },
          "video": video->{
            _id,
            title,
            videoId,
          },
          backgroundImage,
        },
      },
    },
  }`;

  const query = /* js */ `
  *[slug.current == "${slug}"]{
    _id,
    title,
    subtitle,
    slug,
    logo,
    color,
    location,
    "featuredQuote": featuredQuote->{
      _id,
      quote,
      student,
      year,
      school->{
        title,
      }
    },
    twitter,
    seoDescription,
    seoImage,
    tourTitle,
    tourDescription,
    tourLink,
    tourImage,
    mainImage,
    sectionRepeater[]{
      ...,
      title,
      href,
      section[]{
        ...,
        accordion,
        "accordionSection": accordionSection->{
          sectionTitle,
          columns,
          topPadding,
          accordion[]{
            ...,
            defaultOpen,
            accordionTitle,
            accordionContent,
          }
        },
        content,
        photo,
        photos,
        "highlightSection": highlightSection->{
          _id,
          title,
          highlights[]{
            ...,
            title,
            description,
            image,
          }
        },
        tagList,
        "testimonial": testimonial->{
          _id,
          quote,
          student,
          year,

          school->{
            title,
          }
        },
        "video": video->{
          _id,
          title,
          videoId,
        },
        "videoTestimonial": videoTestimonial->{
          _id,
          "testimonial": testimonial->{
            _id,
            quote,
            student,
            year,
            school->{
              title,
            }
          },
          "video": video->{
            _id,
            title,
            videoId,
          },
          backgroundImage,
        },
      },
    },
  }`;

  function getSchool() {
    sanityClient
      .fetch(query)
      .then((data) => {
        setPostData(data[0]);
        console.log("data", data[0]);
      })
      .catch(console.error);
  }

  function getSchoolPreview() {
    console.log("getSchoolPreview");
    previewClient
      .fetch(query)
      .then((data) => {
        console.log("data", data[0]);
        setPostData(data[0]);
      })
      .catch(console.error);
    previewClient.listen(query).subscribe((data) => {
      setPostData(data.result);
    });
  }

  // debounce getSchoolDraftPreview to prevent multiple requests
  const getSchoolDraftPreview = debounce(() => {
    console.log("getSchoolDraftPreview");
    previewClient
      .fetch(draftQuery)
      .then((data) => {
        setPostData(data[0]);
        console.log("data", data[0]);
      })
      .catch(console.error);
  }, 1000);

  function watchSchool() {
    console.log("watching school");
    previewClient.fetch(idQuery).then((data) => {
      setPostId(data[0]._id);
    });
  }

  function listenDraft() {
    console.log("listening draft");
    previewClient.listen(draftQuery).subscribe((data) => {
      getSchoolDraftPreview();
    });
  }

  function checkForDraft(postId) {
    console.log("checking for draft");
    sanityClient
      .fetch(
        /* js */ `
        *[_id == "drafts.${postId}"]{
          _id,
        }`
      )
      .then((draft) => {
        console.log("draft", draft);
        if (draft.length > 0) {
          console.log("draft found");
          getSchoolDraftPreview();
          listenDraft();
        } else {
          console.log("no draft found");
          getSchoolPreview();
        }
      });
  }

  useEffect(() => {
    if (!!postId) {
      console.log("post id", postId);
      checkForDraft(postId);
    }
  }, [postId]);

  useEffect(() => {
    setPreview(queryParams.get("livepreview"));
    if (queryParams.get("livepreview")) {
      watchSchool();
    } else {
      getSchool();
    }
  }, 
  [location]);


  const [oldLinks, setOldLinks] = useState([]);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    if (postData) {
    setOldLinks(postData?.sectionRepeater?.map((section) => {
      return {
        id: section.href,
        title: section.title,
      };
    }));
    }
  }, [postData]);

  useEffect(() => {
     setLinks(oldLinks?.concat({
      id: "tours",
      title: "Tours",
    }));

    }, [oldLinks]);

  useEffect(() => {
    // set root color
    if (postData && postData.color) {
      document.documentElement.style.setProperty(
        "--bg-color",
        postData.color.hex
      );
      document.documentElement.style.setProperty(
        "--bg-color-light",
        postData.color.hex + "11"
      );
    }
  }, [postData]);

  const [hashScrolled, setHashScrolled] = useState(false);

  // react scroll to #hash on page load once data is loaded but only run once using scrollTo

  useEffect(() => {
    if (postData && !hashScrolled) {
      setHashScrolled(true);
      if (window.location.hash) {
        const hash = window.location.hash;
        const element = document.querySelector(hash);

        if (element) {
          const elementPosition = element.getBoundingClientRect().top;

          const offsetPosition = elementPosition;

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      }
    }
  }, [postData]);



  if (!postData) return <div></div>;

  return (
    <>
    <SiteNavigation />
      <Hero
        title={postData.title}
        subtitle={postData.subtitle}
        location={postData.location}
        quote={postData.featuredQuote}
        image={postData.mainImage}
        logo={postData.logo}
      />
 
      {!!oldLinks && !!links && <NavBar links={!!postData.tourTitle ? links : oldLinks} />}
      {postData.sectionRepeater?.map((section, index) => {
        return (
          <ContentSection
            title={section.title}
            id={section.href}
            content={section.section}
            index={index + 1}
          />
        );
      })}
      <OpenHouse
        title={postData.tourTitle}
        description={postData.tourDescription}
        link={postData.tourLink}
        image={postData.tourImage}
      />

      <Footer />
    </>
  );
}
