import { ColumnContainer } from "./ColumnContainer";
import { Container } from "./Container";
import { Introduction } from "./Introduction";
import { SectionHeading } from "./SectionHeading";
import { PortableText } from "@portabletext/react";
import { Photos } from "./Photos";
import { Accordion } from "./Accordion";
import { VideoTestimonial } from "./VideoTestimonial";
import { useState } from "react";
import { urlFor } from "../utils/imageBuilder";
import { Highlights } from "./Highlights";

export function ContentSection(props) {
  return (
    <section
      key={props.index}
      id={props.id}
      aria-labelledby="about-title"
      className="scroll-mt-14 py-16 sm:scroll-mt-32 sm:py-20 lg:py-32 lg:pb-0"
    >
      <ColumnContainer size="lg">
        {props.content &&
          props.content.map((block, index) => (
            <div
              className={
                !!block.columns ||
                block._type === "photo" ||
                block._type === "tagList"
                  ? "col-span-full lg:col-span-3"
                  : "col-span-full"
              }
            >
              {block._type === "content" && (
                <>
                  {!block.columns ? (
                    <Container>
                      {index === 0 && (
                        <>
                          {props && props.title && (
                            <SectionHeading
                              number={props.index.toString()}
                              id="about-title"
                            >
                              {props.title}
                            </SectionHeading>
                          )}
                        </>
                      )}
                      <div className="prose prose-headings:font-condensed prose-headings:tracking-tight prose-h2:text-4xl prose-h3:text-3xl prose-h4:text-2xl prose-sm sm:prose-base mt-8">
                        <PortableText value={block.body} />
                      </div>
                    </Container>
                  ) : (
                    <>
                      {index === 0 && (
                        <>
                          {props && props.title && (
                            <SectionHeading
                              number={props.index.toString()}
                              id="about-title"
                            >
                              {props.title}
                            </SectionHeading>
                          )}
                        </>
                      )}

                      <div className="prose prose-headings:font-condensed prose-headings:tracking-tight prose-h2:text-4xl prose-h3:text-3xl prose-h4:text-2xl prose-sm sm:prose-base mt-8">
                        <PortableText value={block.body} />
                      </div>
                    </>
                  )}
                </>
              )}
              {block._type === "photo" && block.asset && (
                <div className="hidden lg:block relative h-full w-full min-h-[200px] lg:min-h-[400px]">
                  <img
                    src={urlFor(block.asset)
                      .width(900)
                      .url()}
                    alt=""
                    sizes="(min-width: 640px) 18rem, 11rem"
                    className=" w-full h-full object-cover rounded-2xl absolute shadow-2xl"
                  />
                </div>
              )}
              {block._type === "photos" && (
                <div className="w-screen left-1/2 -translate-x-1/2 relative">
                  <Photos photos={block.photoReel} />
                </div>
              )}
              {block._type === "accordionSection" && (
                <>
                  {!block.columns ? (
                    <Container>
                      <div className="divide-y divide-slate-200 gap-2 flex flex-col">
                        {block.sectionTitle && (
                          <h2 className="font-bold text-2xl mt-4">
                            <span className="inline-block">
                              {block.sectionTitle}
                            </span>
                          </h2>
                        )}
                        {block.accordions &&
                          block.accordions.map(
                            (accordion) => (
                              <Accordion
                                title={
                                  accordion.accordionTitle
                                }
                                defaultOpen={
                                  accordion.defaultOpen
                                }
                                children={
                                  <PortableText
                                    value={
                                      accordion.accordionContent
                                    }
                                  />
                                }
                              />
                            )
                          )}
                      </div>
                    </Container>
                  ) : (
                    <div
                      className={`${
                        block.topPadding ? "lg:pt-14" : ""
                      } divide-y divide-slate-200 gap-2 flex flex-col`}
                    >
                      {block.sectionTitle && (
                        <h2 className="font-bold text-2xl mt-4">
                          <span className="hidden sm:inline-block ">
                            {block.sectionTitle}
                          </span>
                        </h2>
                      )}
                      {block.accordions.map((accordion) => (
                        <Accordion
                          title={accordion.accordionTitle}
                          defaultOpen={
                            accordion.defaultOpen
                          }
                          children={
                            <PortableText
                              value={
                                accordion.accordionContent
                              }
                            />
                          }
                        />
                      ))}
                    </div>
                  )}
                </>
              )}
              {block._type === "videoTestimonial" && (
                <div className="w-screen left-1/2 -translate-x-1/2 relative mt-16">
                  <VideoTestimonial
                    author={
                      block.testimonial
                        ? {
                            name: block.testimonial.student,
                            role: block.testimonial.year,
                          }
                        : null
                    }
                    vimeoId={
                      block.video
                        ? block.video.videoId
                        : null
                    }
                    image={block.backgroundImage}
                  >
                    {block.testimonial &&
                      block.testimonial.quote}
                  </VideoTestimonial>
                </div>
              )}
              {block._type === "highlightSection" && (
                <Highlights
                  highlights={
                    block.highlightSection?.highlights
                  }
                />
              )}
              {block._type === "tagList" && (
                <div className="mt-24">
                  <div className="mt-8 flex flex-wrap gap-2 lg:justify-end">
                    {block.tagCategory === "arts" && (
                      <>
                        {block.visualPerformingArtsTags &&
                          block.visualPerformingArtsTags.map(
                            (tag) => (
                              <span className="bg-slate-100 text-slate-600 px-3 py-1.5 rounded-full text-xs font-bold inline-flex items-center">
                                {tag.label}
                              </span>
                            )
                          )}
                      </>
                    )}
                    {block.tagCategory === "athletics" && (
                      <>
                        {block.athleticsTags &&
                          block.athleticsTags.map((tag) => (
                            <span className="bg-slate-100 text-slate-600 px-3 py-1.5 rounded-full text-xs font-bold inline-flex items-center">
                              {tag.label}
                            </span>
                          ))}
                      </>
                    )}
                    {block.tagCategory === "clubs" && (
                      <>
                        {block.clubsTags &&
                          block.clubsTags.map((tag) => (
                            <span className="bg-slate-100 text-slate-600 px-3 py-1.5 rounded-full text-xs font-bold inline-flex items-center">
                              {tag.label}
                            </span>
                          ))}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
      </ColumnContainer>
    </section>
  );
}
