import { Button } from "../components/Button";
import { GridPattern } from "../components/GridPattern";
import { StarRating } from "../components/StarRating";
import coverImage from "../images/cover.png";
import { urlFor } from "../utils/imageBuilder";
import { Distance } from "./Distance";

function Testimonial(props) {
  return (
    <figure className="relative mx-auto max-w-md text-center lg:mx-0 lg:text-left">
      <blockquote className="mt-2">
        <p className="font-display text-xl font-medium text-slate-900">
          &ldquo;{props.quote}&rdquo;
        </p>
      </blockquote>
      <figcaption className="mt-2 text-sm text-slate-500 capitalize">
        <strong className="font-semibold text-[color:var(--bg-color)] before:content-['—_']">
          {props.name}
        </strong>
        , {props.school} {props.year}
      </figcaption>
    </figure>
  );
}

export function Hero(props) {
  return (
    <header className="overflow-hidden bg-slate-100 lg:bg-transparent lg:px-5">
      <div className="mx-auto grid max-w-6xl grid-cols-1 grid-rows-[auto_1fr] gap-y-16 pt-16 md:pt-20 lg:grid-cols-12 lg:gap-y-20 lg:px-3 lg:pb-36 lg:pt-20 xl:py-32 xl:pt-24">
        <div className="relative flex items-end lg:col-span-5 lg:row-span-2">
          <div className="absolute -top-20 -bottom-12 left-0 right-1/2 z-10 rounded-br-6xl bg-[color:var(--bg-color)] text-white/10 md:bottom-8 lg:-inset-y-32 lg:right-full lg:left-[-100vw] lg:-mr-40">
            <GridPattern
              x="100%"
              y="100%"
              patternTransform="translate(112 64)"
            />
          </div>
          <div className="relative z-10 mx-auto flex w-64 rounded-xl bg-slate-600 shadow-xl md:w-80 lg:w-auto aspect-[5/7] overflow-hidden">
            {props.image && (
              <img
                className="w-full object-cover"
                src={urlFor(props.image).width(1280).url()}
                alt=""
                priority
              />
            )}
            {props.staticImage && (
              <img
                className="w-full object-cover"
                src={props.staticImage}
                alt=""
                priority
              />
            )}
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:col-span-7 lg:pr-0 lg:pb-14 lg:pl-16 xl:pl-20">
          <div className="hidden lg:absolute lg:bottom-0 lg:-top-32 lg:right-[-100vw] lg:left-[-100vw] lg:block lg:bg-slate-100" />
          {props.quote && (
            <Testimonial
              quote={props.quote.quote}
              name={props.quote.student}
              year={props.quote.year}
              school={props.quote.school ? props.quote.school.title : ""}
            />
          )}
        </div>
        <div className="bg-white pt-16 lg:col-span-7 lg:bg-transparent lg:pt-0 lg:pl-16 xl:pl-20">
          <div className="mx-auto px-4 sm:px-6 md:max-w-2xl md:px-4 lg:px-0">
            <div className="flex items-end gap-4">
              {props.logo && (
                <img
                  src={urlFor(props.logo).width(240).url()}
                  className="w-auto h-16"
                />
              )}
              {props.staticLogo && (
                <img src={props.staticLogo} className="w-auto h-16" />
              )}
              {!!props.location && (
                <Distance lat={props.location.lat} long={props.location.lng} />
              )}
            </div>
            {props.title && (
              <h1 className="font-display text-5xl font-extrabold mt-2 text-slate-900 sm:text-6xl">
                {props.title}
              </h1>
            )}
            {props.subtitle && (
              <p className="mt-4 text-3xl text-slate-600 font-thin italic">
                {props.subtitle}
              </p>
            )}
            <div className="mt-8 flex gap-4"></div>
          </div>
        </div>
      </div>
    </header>
  );
}
